import { combineReducers } from "redux";
import checkoutReducer from "./checkoutReducer";
import controlStock from "./controlStockReducer";
import establecimientos from "./establecimientosReducer";
import utilidades from "./utilsReducer";

export default combineReducers({
  checkout: checkoutReducer,
  utilidades: utilidades,
  controlStock: controlStock,
  establecimientos: establecimientos,
});
