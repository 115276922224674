// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import qs from "qs";
import { toast } from "react-toastify";

// Instancia para api de partes
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000000,
});

let isLogged = localStorage.getItem("apiToken") ? true : false;

instance.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("goldfilled_access_token");
            localStorage.removeItem("userType");
            window.location.replace(PARAMS.baseUrl);
          } else {
            toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    } else {
      toast("No se ha podido establecer conexión", { type: "error" });
    }

    return Promise.reject(error);
  }
);

// Instancia para ws formData
var instance_fd = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 5000,
});

instance_fd.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance_fd.defaults.headers.common["Content-Type"] = "multipart/form-data";
instance_fd.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    //console.log(error.response);

    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userType");
            window.location.replace(PARAMS.baseUrl);
          } else {
            toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    }
  }
);

// Instancia para la api de Goldfilled
var instance_goldfilled = axios.create({
  baseURL: PARAMS.GOLDFILLED_URL,
  timeout: 10000000,
});

let isLoggedGoldfilled = localStorage.getItem("goldfilled_access_token") ? true : false;

//instance_goldfilled.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiTokenGoldfilled");
// instance_goldfilled.defaults.headers.common["content-type"] = "application/x-www-form-urlencoded; application/json";

//Conseguir el los datos del request de la llamada
instance_goldfilled.interceptors.request.use(
  async config => {
    if (config.url != 'label/v1/auth/token') {
      const token = localStorage.getItem('goldfilled_access_token');
      // const activo = await AsyncStorage.getItem('activo');
      if (token) {
        config.headers.Authorization = "Bearer "+token;
      }
      // checkActivo();
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//Conseguir el los datos del repsonse de la llamada
instance_goldfilled.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiTokenGoldfilled")) {
            toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiTokenGoldfilled");
            localStorage.removeItem("userType");
            window.location.replace(PARAMS.baseUrl);
          } else {
            toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    } else {
      toast("No se ha podido establecer conexión", { type: "error" });
    }

    return Promise.reject(error);
  }
);

// Instancia para la api de Goldfilled - Mirakl
var instance_mirakl = axios.create({
  baseURL: PARAMS.apiGoldfilledMiraklUrl,
  timeout: 10000000,
});

let isLoggedGoldfilledMirakl = localStorage.getItem("apiTokenGoldfilled") ? true : false;

instance_mirakl.defaults.headers.common["Authorization"] = PARAMS.GOLDFILLED_API_KEY;
//instance_mirakl.defaults.headers.common["content-type"] = "application/x-www-form-urlencoded";

//Conseguir el los datos del request de la llamada
instance_mirakl.interceptors.request.use(
  async config => {
    // if (config.url != 'label/v1/auth/token') {
    //   const token = localStorage.getItem('apiTokenGoldfilled');
    //   // const activo = await AsyncStorage.getItem('activo');
    //   if (token) {
    //     config.headers.Authorization = "Bearer "+token;
    //   }
    //   // checkActivo();
    // }
    console.log(config);

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//Conseguir el los datos del repsonse de la llamada
instance_mirakl.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        // case 401:
        //   if (localStorage.getItem("apiTokenGoldfilled")) {
        //     toast("Sesión caducada", { type: "error" });
        //     axios.get("logout");
        //     localStorage.removeItem("apiTokenGoldfilled");
        //     localStorage.removeItem("userType");
        //     window.location.replace(PARAMS.baseUrl);
        //   } else {
        //     toast("Usuario no autorizado", { type: "error" });
        //   }
        //   break;
        case 404:
          toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    } else {
      toast("No se ha podido establecer conexión", { type: "error" });
    }

    return Promise.reject(error);
  }
);

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await promise;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      resolved.error = e.response.data.error;
    } else {
      resolved.error = "Se ha producido un error";
    }
  }

  return resolved;
}

// File a base64
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  return await resolve(instance.post("isAdminAuth").then((res) => res.data));
}

// Ruta para verificar tipo de usuario (no token)
export async function isAdminGuest() {
  return await resolve(instance.post("isAdminGuest").then((res) => res.data));
}

export async function login(email, password) {
  return await resolve(
    instance.post("login", { email, password }).then((res) => res.data)
  );
}

export async function register(
  nombre,
  apellidos,
  nif,
  email,
  password,
  userType
) {
  return await resolve(
    instance
      .post("register", { nombre, apellidos, nif, email, password, userType })
      .then((res) => res.data)
  );
}

export async function resetPass(email) {
  return await resolve(
    instance.post("resetPassword/create", { email }).then((res) => res.data)
  );
}

export async function logout() {
  return await resolve(
    instance.get("logout").then((res) => res.data)
    );
}

export async function getPermiso() {
  return await resolve(instance.post("permiso/index").then((res) => res.data));
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Usuarios --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getUser(email_usuario) {
  return await resolve(
    instance.post("usuarios/show", { email_usuario }).then((res) => res.data)
  );
}

export async function userIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("usuarios/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function usuarioStore(usuario) {
  return await resolve(
    instance.post("usuarios/store", usuario).then((res) => res.data)
  );
}

export async function userStore(
  nombre,
  email,
  permiso_id,
  password,
  apellidos,
  puesto,
  company
) {
  return await resolve(
    instance
      .post("usuarios/store", {
        nombre,
        email,
        permiso_id,
        password,
        apellidos,
        puesto,
        company,
      })
      .then((res) => res.data)
  );
}

export async function userUpdate(usuario, pass) {
  let fd = new FormData();

  for (var key in usuario) {
    if (usuario[key]) {
      fd.append(key, usuario[key]);
    }
  }

  if (pass) {
    fd.append("password", pass);
  }

  return await resolve(
    instance_fd
      .post(`usuarios/update/${usuario.id}`, fd)
      .then((res) => res.data)
  );
}

export async function userDestroy(id) {
  return await resolve(
    instance.post(`usuarios/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- CLientes -------------------------------------------
 * ----------------------------------------------------------------------------------------*/
export async function cliente(id) {
  return await resolve(
    instance.post(`clientes/show/${id}`).then((res) => res.data)
  );
}

export async function clienteStore(cliente) {
  return await resolve(
    instance.post("clientes/store", cliente).then((res) => res.data)
  );
}



/** --------------------------------------------------------------------------------------
 * ----------------------------------- Logs --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function logIndex() {
  return await resolve(instance.post("log/index").then((res) => res.data));
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Permisos --------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function permisosList(search) {
  return await resolve(
    instance.post("permiso/list", { search }).then((res) => res.data)
  );
}

export async function permisoStore(nombre, permiso_modelos) {
  return await resolve(
    instance
      .post("permiso/store", { nombre, permiso_modelos })
      .then((res) => res.data)
  );
}

export async function permisoUpdate(id, nombre, permiso_modelos) {
  return await resolve(
    instance
      .post(`permiso/update/${id}`, { nombre, permiso_modelos })
      .then((res) => res.data)
  );
}

export async function permisoDestroy(id) {
  return await resolve(
    instance.post(`permiso/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Establecimientos -----------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function establecimientosIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("establecimientos/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function establecimientosStore(establecimiento) {
  let fd = new FormData();
  for (var key in establecimiento) {
    if (establecimiento[key]) {
      fd.append(key, establecimiento[key]);
    }
  }
  return await resolve(
    instance_fd.post("establecimientos/store", fd).then((res) => res.data)
  );
}

export async function establecimientosUpdate(establecimiento) {
  let fd = new FormData();
  for (var key in establecimiento) {
    if (establecimiento[key]) {
      fd.append(key, establecimiento[key]);
    }
  }
  return await resolve(
    instance_fd
      .post(`establecimientos/update/${establecimiento.id}`, fd)
      .then((res) => res.data)
  );
}

export async function establecimientosDestroy(id) {
  return await resolve(
    instance.post(`establecimientos/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Categorias -----------------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function getCategoriasActivas() {
  // return await resolve(
  //   instance.post("categorias/categoriasActivas").then((res) => res.data)
  // );
  return await resolve(
    instance.get("categorias/categoriasActivas").then((res) => res.data)
  );
}

export async function categoriasIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("categorias/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}
export async function categoriasStore(categoria) {
  let fd = new FormData();
  for (var key in categoria) {
    if (categoria[key]) {
      fd.append(key, categoria[key]);
    }
  }
  return await resolve(
    instance_fd.post("categorias/store", fd).then((res) => res.data)
  );
}
export async function categoriasUpdate(categoria) {
  let fd = new FormData();
  for (var key in categoria) {
    if (categoria[key]) {
      fd.append(key, categoria[key]);
    }
  }
  return await resolve(
    instance_fd
      .post(`categorias/update/${categoria.id}`, fd)
      .then((res) => res.data)
  );
}
export async function categoriasDestroy(id) {
  return await resolve(
    instance.post(`categorias/destroy/${id}`).then((res) => res.data)
  );
}
export async function mostrarTodasCategorias() {
  return await resolve(
    instance.post(`categorias/showAll`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- Productos ------------------------------------------
 * ----------------------------------------------------------------------------------------*/
export async function productoPorId(id) {
  return await resolve(
    instance.get(`productos/show/${id}`).then((res) => res.data)
  );

  // return await resolve(
  //   instance.post(`productos/show/${id}`).then((res) => res.data)
  // );
}

export async function productosPorCategoria(id) {
  return await resolve(
    instance.get(`productos/porCategoria/${id}`).then((res) => res.data)
  );
  // return await resolve(
  //   instance.post(`productos/porCategoria/${id}`).then((res) => res.data)
  // );
}

export async function productosIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("productos/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function productosCatalogoIndex(search, page, perPageData) {
  return await resolve(
    instance
      .post("productos/establecimiento/index", { search, page, perPageData })
      .then((res) => res.data)
  );
}

export async function productosStore(producto) {
  let fd = new FormData();
  for (var key in producto) {
    if (producto[key]) {
      fd.append(key, producto[key]);
    }
  }
  return await resolve(
    instance_fd.post("productos/store", fd).then((res) => res.data)
  );
}

export async function productosUpdate(producto) {
  let fd = new FormData();
  for (var key in producto) {
    if (producto[key]) {
      fd.append(key, producto[key]);
    }
  }
  return await resolve(
    instance_fd
      .post(`productos/update/${producto.id}`, fd)
      .then((res) => res.data)
  );
}

export async function productosDestroy(id) {
  return await resolve(
    instance.post(`productos/destroy/${id}`).then((res) => res.data)
  );
}

/** --------------------------------------------------------------------------------------
 * ----------------------------------- ClienteDatosFactura ---------------------------------
 * ----------------------------------------------------------------------------------------*/

export async function clienteDatosFactura(id) {
  return await resolve(
    instance.post(`clienteDatosFactura/show/${id}`).then((res) => res.data)
  );
}

export async function clienteDatosFacturaStore(clienteDatosFactura) {
  return await resolve(
    instance
      .post("clienteDatosFactura/store", clienteDatosFactura)
      .then((res) => res.data)
  );
}


/** --------------------------------------------------------------------------------------
 * ----------------------------------- FAQ -----------------------------------------------
 * ----------------------------------------------------------------------------------------*/

 export async function faqsIndex(establecimiento_id) {
  return await resolve(
    instance.post('faq/index', {establecimiento_id}).then(res => res.data)
  )
}

export async function faqStore(faq) {
  return await resolve(
    instance.post('faq/store', faq).then(res => res.data)
  )
}

export async function faqUpdate(faq) {
  return await resolve(
    instance.post(`faq/update/${faq.id}`, faq).then(res => res.data)
  )
}

export async function faqDestroy(id) {
  return await resolve(
    instance.post(`faq/destroy/${id}`).then(res => res.data)
  )
}

export async function faqOrder(orden, faq_padre_id, establecimiento_id) {
  return await resolve(
    instance.post(`faq/order`, {orden, faq_padre_id, establecimiento_id}).then(res => res.data)
  )
}

export async function showFaqs(establecimiento_id) {
  return await resolve(
    instance.post(`faq/all`,{establecimiento_id}).then(res => res.data)
  )
}

/** -------------------------------------------------------------------------------------- *
 * ----------------------------------- GOLDFILLED - SRP ---------------------------------- *
 * -------------------------------------------------------------------------------------- **/

/* ---------------------------------------- Login ---------------------------------------- */

export async function loginGoldfilled() {
    // let str = qs.stringify({
    //     grant_type:`password`,
    //     client_id:PARAMS.GOLDFILLED_CLIENT_ID,
    //     username:PARAMS.GOLDFILLED_USERNAME,
    //     password:PARAMS.GOLDFILLED_PASSWORD
    // });
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', PARAMS.GOLDFILLED_CLIENT_ID);
    params.append('username', PARAMS.GOLDFILLED_USERNAME);
    params.append('password', PARAMS.GOLDFILLED_PASSWORD);
    return await resolve(
        instance_goldfilled.post("label/v1/auth/token", params).then((res) => res.data)
    );
}

/* ---------------------------------------- Imprimir etiqueta---------------------------- */

export async function crearEtiquetaEnvio(id_pedido) {
    const params = new URLSearchParams();
    params.append('ReferenceId', id_pedido);
    params.append('ReferenceSource', 'SRP');
    params.append('Mode', 'Shipping');
    return await resolve(
      //print y create
        instance_goldfilled.post("label/v1/create", params).then((res) => res.data)
    );
}

export async function printEtiquetaEnvio(id_pedido) {
  const params = new URLSearchParams();
  params.append('ReferenceId', id_pedido);
  params.append('ReferenceSource', 'SRP');
  params.append('Mode', 'Shipping');
  return await resolve(
    //print y create
      instance_goldfilled.post("label/v1/print", params).then((res) => res.data)
  );
}

/** -------------------------------------------------------------------------------------- *
 * ----------------------------------- GOLDFILLED - Mirakl-------------------------------- *
 * -------------------------------------------------------------------------------------- **/

/* --------------------------------------- Pedidos --------------------------------------- */

export async function getPedidosGoldfilled(date) {
    return await resolve(
        instance_mirakl.get("/api/orders?paginate=FALSE"+date).then((res) => res.data)
    );
}

export async function listPedidos(arrayPedidos) {
  return await resolve(
    instance.post('pedidos/listPedidos', {arrayPedidos}).then(res => res.data)
  )
}

export async function comprobarNum(numPedido) {
  return await resolve(
    instance.post('pedidos/check', {numPedido}).then(res => res.data)
  )
}

export async function updatePedido(numPedido) {
  return await resolve(
    instance.post('pedidos/update', {numPedido}).then(res => res.data)
  )
}

export async function cancelarPedido(order_id){
    return await resolve(
        instance_mirakl.put('/api/orders/'+order_id+'/cancel').then((res) => res.data)
    );
}

export async function validarPedido(order_id){
    return await resolve(
        instance_mirakl.put('/api/orders/'+order_id+'/ship').then((res) => res.data)
    );
}

export async function actualizarDatosTracking(infoTransportista){
    let order_id = infoTransportista.order_id;
    delete infoTransportista.order_id;
    return await resolve(
        instance_mirakl.put('/api/orders/'+order_id+'/tracking', JSON.stringify(infoTransportista), {headers: {'content-type':'application/json'}}).then((res) => res.data)
    );
}

export async function EtiquetasStore(slider) {
  let fd = new FormData();

  for (var key in slider) {
    if (slider[key]) {
      fd.append(key, slider[key]);
    }
  }

  return await resolve(
    instance_fd.post('slider/store', fd).then(res => res.data)
  )
}

/* ------------------------------------- Productos ------------------------------------- */

export async function subirProductos(infoTransportista){
    let order_id = infoTransportista.order_id;
    delete infoTransportista.order_id;
    return await resolve(
        instance_mirakl.post('/api/orders/'+order_id+'/tracking', JSON.stringify(infoTransportista), {headers: {'content-type':'application/json'}}).then((res) => res.data)
    );
}

/* -------------------------------------- Ofertas -------------------------------------- */


export async function subirOfertas(archivo){
    let fd = new FormData();
    for (var key in archivo) {
        if (archivo[key]) {
            fd.append(key, archivo[key]);
        }
    }
    return await resolve(
        instance_mirakl.post('/api/offers/imports', JSON.stringify(fd), {headers: {'content-type':'application/json'}}).then((res) => res.data)
    );
}
