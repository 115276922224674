export const OBTENER_PRODUCTOS_LOADING = "OBTENER_PRODUCTOS_LOADING";
export const OBTENER_PRODUCTOS_EXITO = "OBTENER_PRODUCTOS_EXITO";
export const OBTENER_PRODUCTOS_ERROR = "OBTENER_PRODUCTOS_ERROR";
export const CERRAR_MODAL = "CERRAR_MODAL";

export const COMPROBAR_STOCK_LOADING = "COMPROBAR_STOCK_LOADING";
export const COMPROBAR_STOCK_LOADING_EXITO = "COMPROBAR_STOCK_LOADING_EXITO";
export const COMPROBAR_STOCK_LOADING_ERROR = "COMPROBAR_STOCK_LOADING_ERROR";

export const OBTENER_ESTABLECIMIENTO_LOADING =
  "OBTENER_ESTABLECIMIENTO_LOADING";
export const OBTENER_ESTABLECIMIENTO_LOADING_EXITO =
  "OBTENER_ESTABLECIMIENTO_LOADING_EXITO";
export const OBTENER_ESTABLECIMIENTO_LOADING_ERROR =
  "OBTENER_ESTABLECIMIENTO_LOADING_ERROR";
