import loginAdminImg from "assets/img/admin_img.jpg";
import iconImg from "assets/img/logo-goldfilled-admin.png";
import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params['baseUrl'] = 'https://dropshowroom.es/';
params['apiUrl'] = 'https://api.dropshowroom.es/api/';
params['loginAdminImg'] = loginAdminImg;
params['sideBarIcon'] = iconImg;
params['sideBarImg'] = sideBarImg;
params['sideBarColor'] = '#c78b43';
params['firstColorRGB'] = '199, 139, 67';
params['firstColor'] = '#c78b43';
params['firstColorHover'] = '#004569';
params['secondColor'] = '#004569';
params['color_green'] = '#00C534';
params['color_red'] = '#FF0C56';
params['color_grey'] = '#BBBBBB';
params['proyectName'] = 'Goldfilled Dropshipping';
params['defaultTableLength'] = 100;
params['tableLengthArray'] = [10, 25, 50, 100];
params['personalizacion'] = false;
params['dev_mode'] = false;
params['panel_url'] = 'administrador';
params['web_url'] = 'entidad';
params['GOOGLE_API_KEY'] = 'AIzaSyAfsDJ2Ee7XCcfvUg1xwEmk-XY5bRfbOO4';

//Datos para la api de Goldfilled
//Mirakl - Demos
// params['apiGoldfilledMiraklUrl'] = 'https://showroomprive2-dev.mirakl.net/'; //Esta es la de test
//params['GOLDFILLED_API_KEY'] = 'fc55fe5a-0728-4cab-b3e3-c7bb21fd069c'; //SHOP_KEY Antigua
// params['GOLDFILLED_API_KEY'] = '895ce1a0-6ad4-4c25-9fca-68c89a41b985'; //SHOP_KEY
//Datos para sacar el token de autenticación y crear la label
// params['GOLDFILLED_URL'] = "https://label.prawf.showroomprive.net/5fjpdteijj5wpkedhfzoy/api/";

//Mirakl - Producción
params['apiGoldfilledMiraklUrl'] = 'https://showroomprive-prod.mirakl.net';
params['GOLDFILLED_API_KEY'] = '815fa955-3203-411e-aa3c-16e4a485051a';
params['GOLDFILLED_URL'] = "https://label.service.showroomprive.com/rgngnw4362hmxwee4dgxn/api/";

//Nuevos datos de Goldfilled - Funcionales Demos
// params['GOLDFILLED_CLIENT_ID'] = '0926096f-c191-41b2-a90b-959aa839db65';
// params['GOLDFILLED_USERNAME'] = 'GOLDFILLED';
// params['GOLDFILLED_PASSWORD'] = 'w48t9CGA(!';

//Nuevos datos de Goldfilled - Funcionales Produccion
params['GOLDFILLED_CLIENT_ID'] = '0926096f-c191-41b2-a90b-959aa839db65';
params['GOLDFILLED_USERNAME'] = 'GOLDFILLED';
params['GOLDFILLED_PASSWORD'] = '!4Dtcte7gN';

export default params;
