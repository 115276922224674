import { COMPROBAR_STOCK_LOADING_ERROR } from "redux/types";
import { COMPROBAR_STOCK_LOADING_EXITO } from "redux/types";
import { COMPROBAR_STOCK_LOADING } from "redux/types";

const initialState = {
  stock: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPROBAR_STOCK_LOADING:
      return {
        ...state,
      };
    case COMPROBAR_STOCK_LOADING_EXITO:
      return {
        ...state,
        stock: action.payload.checkStock,
      };
    case COMPROBAR_STOCK_LOADING_ERROR:
      return {
        ...state,
      };
    default:
      return state;
      break;
  }
}
