import { OBTENER_PRODUCTOS_ERROR } from "redux/types";
import { OBTENER_PRODUCTOS_EXITO } from "redux/types";
import { OBTENER_PRODUCTOS_LOADING } from "redux/types";

const initialState = {
  estado: false,
  productos: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OBTENER_PRODUCTOS_LOADING:
      return {
        ...state,
      };
    case OBTENER_PRODUCTOS_EXITO:
      return {
        ...state,
        estado: true,
        productos: action.payload.array,
      };
    case OBTENER_PRODUCTOS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
      break;
  }
}
